import React from 'react';
import logo from "../assets/images/2SaveALife.webp";
import "../assets/css/navbar.css"

const Navbar = () => {
    return (


        <nav className='nav container'>
            <div className="logo">

                <img src={logo} alt="2 Save A Life" />

            </div>

            <div>
                <ul>
                    <li> <a href="/home">Home</a> </li>
                    <li><a href="/contact">Contact</a></li>
                    <li><a href="/register">Register</a></li>
                    <li><a href="/terms">Terms and Conditions</a></li>
                </ul>
            </div>
        </nav>

    )
}

export default Navbar