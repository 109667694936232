import { useState } from "react";
import "./formInput.css";

const FormInput = (props) => {
  const [focused, setFocused] = useState(false);
  const { label, errorMessage, onChange, id, ...inputProps } = props;

  const handleFocus = (e) => {
    setFocused(true);
  };

  return (
    <div className="formInput">
      <label>{label}</label>
      <input
        {...inputProps}
        onChange={onChange}
        onBlur={handleFocus}
        onFocus={() =>
          inputProps.name === "confirmPassword" && setFocused(true)
        }
        focused={focused.toString()}
      />
      <span>{errorMessage}</span>
    </div>
  );
};

export const Checkbox = ({ name, isChecked, label, checkHandler, index, ...props}) => {
  console.log({"isChecked:": isChecked })
  return (
    <div>
      <input
        name={name}
        type="checkbox"
        id={`checkbox-${index}`}
        onChange={checkHandler}
        {...props}
      />
      <label htmlFor={`checkbox-${index}`}>{label}</label>
    </div>
  )
}
export default FormInput;
