// Filename - pages/contact.js

import React from "react";
import "../assets/css/home.css";

const Contact = () => {
    return (
        <div className="section" >
            <div className="row">

                <div className="">
                    <div className="">
                        <h2 ><span >Contact Us</span></h2>
                    </div>
                    <div className="wn-contacts">
                        To learn more contact:
                        <p>Anthony.diPasquale@2SaveALifeLLC.com </p>
                        <p>610-888-4765</p>
                        <p>For technical support contact:</p>
                        <p>George Miller:  George@2SaveALifeLLC.com</p>
                    </div>
                </div>
            </div>


        </div>
    );
};

export default Contact;
