// Filename - App.js

import React from "react";
import Navbar from "./components/navbar";
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import Home from "./pages/home";
import Register from "./pages/register";
import Contact from "./pages/contact";
import Terms from "./pages/terms";
import "./app.css";
import "./assets/css/navbar.css";
import "./assets/css/style.css"
import { Navigate } from 'react-router-dom';

function App() {
    return (
        <Router>
            <Navbar />
            <main className="main-content">
                <Routes>
                    <Route exact path="/" element={<Home />} />
                    <Route exact path="/home" element={<Home />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/terms" element={<Terms />} />
                    <Route path='*' element={<Navigate to='/' />} />
                </Routes>
            </main>
        </Router>
    );
}

export default App;
