
import { useState } from "react";
import "./register.css";
import FormInput, { Checkbox } from "../components/FormInput";

const Register = () => {
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    acceptTerms: "",
  });


  const [checkboxValues, setCheckboxValues] = useState(
    {
      name: "acceptTerms",
      isChecked: false,
      chkbxLabel: " I agree to receive SMS messages and to the Terms and Conditions"
    },
  )

  const inputs = [
    {
      id: 1,
      name: "firstName",
      type: "text",
      placeholder: "First Name",
      errorMessage:
        "Firts name should be 3-70 characters and shouldn't include any special character!",
      label: "First Name:",
      pattern: "^[A-Za-z]{3,70}$",
      required: true,
    },
    {
      id: 2,
      name: "lastName",
      type: "text",
      placeholder: "Last Name",
      errorMessage:
        "Firts name should be 3-70 characters and shouldn't include any special character!",
      label: "Last Name:",
      pattern: "^[A-Za-z]{3,70}$",
      required: true,
    },
    {
      id: 3,
      name: "phoneNumber",
      type: "phone",
      placeholder: "10 digit Phone Number",
      label: "Alert Phone Number:",
      required: true,

    },
  ];

  const handleCheckbox = (e) => {

    setCheckboxValues({ ...checkboxValues, isChecked: !checkboxValues.isChecked })
    setValues({ ...values, acceptTerms: e.target.checked })
    console.log(!checkboxValues.isChecked)
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log(values)
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    console.log(e.target.value)
    console.log("acceptTerms out checkbox:", values.acceptTerms)
  };

  return (
    <div className="app">
      <form onSubmit={handleSubmit}>
        <h1>Register</h1>
        {inputs.map((input) => (
          <FormInput
            key={input.id}
            {...input}
            value={values[input.name]}
            onChange={onChange}
          />))}


        <div className="checkbox">
          <Checkbox
            name={checkboxValues.name}
            isChecked={checkboxValues.isChecked}
            checkHandler={handleCheckbox}
            label={checkboxValues.chkbxLabel}
            index={0}
          />
        </div>
        <button>Submit</button>
      </form>
    </div>
  );
};

export default Register;
