const Terms = () => {
    return (
        <div className="container">
            <h1>Terms and Conditions for SMS Messaging</h1>
            <p>These terms and conditions govern your use of our SMS messaging service. Please read them carefully before opting in to our service.</p>

            <h2>Service Description</h2>
            <p>Our SMS messaging service allows users to receive updates, and notifications via text message on their mobile phones.</p>

            <h2>Opt-In and Opt-Out</h2>
            <p>By opting in, you consent to receive SMS messages from us.  Standard message and data rates may apply and are due to your messaging service provider.  2 Save A Life does not add any fees to your messaging bill.  You can opt-out at any time by replying 'STOP' to any message or contacting our customer support.</p>

            <h2>Message Frequency</h2>
            <p>Message frequency may vary based on updates, or events. We strive to limit messages to a reasonable frequency.</p>

            <h2>Privacy and Data Usage</h2>
            <p>We prioritize the privacy of your information. Your mobile number and personal data will not be shared or sold to third parties without your explicit consent.</p>

            <h2>Message Content</h2>
            <p>Messages may include company updates, alerts, or information related to our services. We are not liable for the accuracy or completeness of the information provided in these messages.</p>

            <h2>Support and Assistance</h2>
            <p>For help or inquiries regarding our SMS service, contact our customer support at support@2SaveALifeLLC.com.</p>

            <h2>Modification of Terms</h2>
            <p>We reserve the right to modify or update these terms and conditions at any time. Any changes will be effective immediately upon posting the revised terms on our website or through other communication channels.</p>

            <h2>Termination of Service</h2>
            <p>We reserve the right to suspend or terminate the SMS messaging service without prior notice or liability.</p>

            <h2>Limitation of Liability</h2>
            <p>We shall not be liable for any direct, indirect, incidental, special, or consequential damages resulting from the use or inability to use our SMS messaging service.</p>

            <h2>Governing Law</h2>
            <p>These terms and conditions shall be governed by and construed in accordance with the laws of Pennsylvainia.</p>
        </div>

    )
}

export default Terms;
