import React, { Component } from "react";
import "../assets/css/home.css";
import chicago from "../assets/images/LocationChiagoTribune.webp";
import threeWaves from "../assets/images/3WavesOfOpiods.webp";
import atlanticOdLocations from "../assets/images/LocationsAtlantic.webp";
import cnnOdLocations from "../assets/images/LocationsCNN.webp";
import RiseInOverdoses from "../assets/images/RiseInOverdoses.webp"

class Home extends Component {
    render() {

        return (
            <>
                <section className="section1">
                    <div className="background-img">
                        <div className="row">
                            <h1>The overdose crisis is national, but the impact is personal.</h1>
                        </div>
                    </div>
                </section>
                <section >
                    <div className="background-img2">
                        <div className="section__container section row">
                            <h3 className="header__text">Dr. Rahul Gupta, National Drug Control Policy Director</h3>

                            <div className="row">
                                <p>“For the first time in our Nation’s history we have passed the tragic milestone of 100,000 deaths resulting from drug overdoses in a 12-month period. Since 1999, drug overdoses have killed approximately 1 million Americans. These are sons and daughters, parents and grandparents, neighbors and friends, and classmates and coworkers. This level of loss is heartbreaking and frankly, unacceptable. As we continue to lose an American life to drug overdose every five minutes around the clock, we find ourselves at an inflection point where we, as a Nation, must commit ourselves to doing what we know will help us triumph over this crisis.” &nbsp;</p>
                            </div></div>
                    </div>
                </section>
                <section>
                    <div>
                        <img src={RiseInOverdoses} alt=""></img>
                    </div>

                </section>
                <section>
                    <div >
                        <img src={threeWaves} alt=""></img>
                    </div>

                </section>
                <section>
                    <div>
                        <img src={atlanticOdLocations} alt=""></img>
                    </div>

                </section>
                <section>
                    <div>
                        <img src={chicago} alt=""></img>
                    </div>

                </section>
                <section>
                    <div>
                        <img src={cnnOdLocations} alt=""></img>
                    </div>

                </section>
                <footer>
                    <footer className="section__footer " >

                        <p className="footer__copyright">Copyright &copy; 2024 <a className="footer__link" href="https://www.2SaveALifeLLC.com/" target="_blank" rel="noreferrer">2 Save A Life LLC</a>  </p>

                    </footer>
                </footer>
            </>
        )
    }
}

export default Home;